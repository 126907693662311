import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {GET_PUBMESSAGGIPENDING} from "../GraphQl/query";
import {Query} from 'react-apollo';
import GenericListContainer from "../GenericList/GenericListContainer";

class PubMessaggiPendingList extends Component {
    render() {
        return (
            <Query query={GET_PUBMESSAGGIPENDING}>
                {({loading, error, data}) => {
                    if (loading) return <div>Fetching</div>;
                    if (error) return error.message;

                    return (
                        <GenericListContainer list={data.pubMessaggiPending} structureName={"PubMessaggiPending"}
                        />
                    )
                }}
            </Query>
        )
    }
}

export default withRouter(PubMessaggiPendingList);
