/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

const RowInput = ({
  field: {...fields},
  form: {touched, errors},
  maxLength,
  labelcols,
  showuntouched,
  label,
  labelClass,
  formGroupClass,
  ...props
}) =>
  <FormGroup row className={formGroupClass}>
    <Label xs={labelcols} for={fields.name} className={`label-color text-left ${labelClass}`}>{label}</Label>
    <Col xs={12 - labelcols}>
      <Input {...props} {...fields} invalid={(showuntouched || touched[fields.name]) && !!errors[fields.name]} maxLength={maxLength}/>
      <FormFeedback>{errors[fields.name]}</FormFeedback>
    </Col>
  </FormGroup>

RowInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number,        // num colonne per etichetta
  showuntouched: PropTypes.bool,       // mostra errore anche campo non touched (es. numerici)
  formGroupClass: PropTypes.string,
  maxLength: PropTypes.number
};

 RowInput.defaultProps = {
  labelcols: 3,
  formGroupClass: "",
  showuntouched: false,
   maxLength: null
 };

export default RowInput;
