import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo'
import GenericListComponent from "./GenericListComponent";
import { Button, Container } from "reactstrap";
import moment from "moment";
import { DATE_FORMAT } from "../Helpers/constants";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import { client } from "../GraphQl";
import { useParams } from "react-router-dom";
import { getTableStructure, getType, getStructureQuery } from "../Helpers/functions";
import { fromCamelCaseConverter } from "../Helpers/filters";
import { useTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";



const prepare = (structureName, schema, detailsBtnLabel, linkFunction, secondLink, secondDetailsBtn, headersName, parentName, list) => {
    const Cell = row => (
        <div>
            {row.value}
        </div>
    );

    const DateCell = row => (
        <div>
            {moment(row.value).format(DATE_FORMAT)}
        </div>
    );

    const CellDeleteButton = row => (
        <Button
            color={"danger"}
            onClick={() => console.log(`delete category with id ${row.original.id}`)}
        >Delete
        </Button>
    );

    const CellCheckbox = row => (
        <input
            readOnly
            type="checkbox"
            className="checkbox"
            checked={row.original.active === true}
        />
    );

    function setButton(link, label) {
        return row => (
            <Button
                color={"info"}
                onClick={() => link(row.original.id)}
            >{label}</Button>);
    }

    function getDetailCell(col) {
        switch (col.accessor) {
            case headersName[0]:
                return setButton(linkFunction, detailsBtnLabel);
            case headersName[1]:
                return setButton(secondLink, secondDetailsBtn);
            default:
                return setButton(linkFunction, detailsBtnLabel);
        }

    }

    const renderEditable = (cellInfo) => {
        const _change = (e) => {
            const cellData = list;
            cellData[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            list = cellData;
        };
        return (
            <div
                contentEditable
                suppressContentEditableWarning
                onKeyPress={handleKeyPress(_change)}
                onBlur={_change}
                dangerouslySetInnerHTML={{
                    __html: list[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    };

    const colMapper = (col) => {
        const type = getType(col, structureName);
        switch (type) {
            case "Date":
                col.Cell = DateCell;
                break;
            case "Delete":
                col.Cell = CellDeleteButton;
                break;
            case "Boolean":
                col.Cell = CellCheckbox;
                break;
            case "String":
                if (col.accessor === "items")
                    col.Cell = getDetailCell(col);
                else
                    col.Cell = renderEditable;
                break;
            case null:
                if (col.type.ofType.kind === "LIST") {
                    col.Cell = getDetailCell(col);
                }
                break;
            case parentName:
                col.show = false;
                break;
            default:
                col.Cell = Cell;
        }
        return col;
    };
    return schema.map(colMapper);
};


const handleKeyPress = change => (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
        event.returnValue = false;
        change(event);
        if (event.preventDefault) event.preventDefault();
    }
};

const applyTranslate = (tableStruct) => {
    tableStruct.forEach((item, index) => {
        tableStruct[index].Header = fromCamelCaseConverter(item.Header);
    });
};

const GenericListContainer = ({
    list, structureName, secondLink, secondDetailsBtn, detailsBtnLabel,
    linkFunction, headers, parentName
}) => {
    const { t } = useTranslation();
    let { id } = useParams();
    const { data, loading, error } = useQuery(getStructureQuery(structureName), {
        variables: { id: id, structureName: structureName },
        fetchPolicy: "network-only"
    });
    const tableStruct = getTableStructure(data);
    applyTranslate(tableStruct);
    const headersName = headers ? headers : [];

    useEffect(() => () => client.cache.reset(), []);
    console.log(list);

    if (loading) return <div>Fetching</div>;
    if (error) return <div>{error.message}</div>;
    return (

        <Container fluid>
            <Row>
                <Col md={9}>
                    <h2>{t(structureName)}</h2>
                </Col>
                <Col>
                    <CSVLink 
                    filename={t(structureName)+".csv"}
                    data={list} 
                    separator={";"}
                    >
                        <Button color="success">
                            Scarica
                        </Button>
                    </CSVLink>
                </Col>
            </Row>
            <Row md={12}>
                <Col>
                    <GenericListComponent
                        columns={prepare(structureName, tableStruct, detailsBtnLabel, linkFunction, secondLink, secondDetailsBtn, headersName, parentName, list)}
                        data={list} />
                </Col>
            </Row>

        </Container>);
};

export default GenericListContainer
