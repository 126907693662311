import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import './App.css';
import {ApolloProvider} from "@apollo/react-common";

import AppRoutes from '../../navigation/AppRoutes';
import {client} from '../GraphQl/index'

import {I18nextProvider, initReactI18next} from 'react-i18next';
import i18n from "i18next";
import {i18nTrans, lang} from "../resources";
import AuthFormContainer from "../AuthForm/AuthFormContainer";
import {GET_LOGIN_DATA} from "../GraphQl/query";
import {useQuery} from "@apollo/react-hooks";
import NavbarComponent from "../Navbar/NavbarComponent";


i18n
    .use(initReactI18next)
    .init({
        resources: i18nTrans,
        lng: lang,

    });


function IsLoggedIn() {
    const {data: isLoggedIn} = useQuery(GET_LOGIN_DATA);
    if (isLoggedIn)
        return isLoggedIn.isLoggedIn ? <AppRoutes/> : <AuthFormContainer/>;
    else
        return <AuthFormContainer/>
}

const AppComponent = () =>
    <div className="App">
        <ApolloProvider client={client}>
            <I18nextProvider lng="en" i18n={i18n}>
                <Router>
                    <NavbarComponent/>
                    <IsLoggedIn/>
                </Router>
            </I18nextProvider>
        </ApolloProvider>
    </div>;

AppComponent.propTypes = {};

AppComponent.defaultProps = {};

export default AppComponent;
