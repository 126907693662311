import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { GET_VIEWS } from "../GraphQl/query";
import { Query } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { Button, Table, Container, Row } from 'reactstrap';
import Col from 'reactstrap/es/Col';

class ViewsList extends Component {



    toView = (path) => {
        this.props.history.push(`/${path}`)
    };


    render() {
        const { t } = this.props;
        return (
            <Query query={GET_VIEWS}>
                {({ loading, error, data }) => {
                    if (loading) return <div>Fetching</div>;
                    if (error) return error.message;

                    return (
                        <Container fluid>
                            <Row style={{marginBottom:"5%"}}>
                                <Col>
                                    <h2>Ufficio 5</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table borderless style={{ width: "50%", marginRight: "auto", marginLeft: "auto" }}>
                                        <tbody>
                                            {data.views.map((data, i) => {
                                                return <tr key={i}>
                                                    <td style={{textAlign: "left", verticalAlign: "middle"}}>{t(data.name)}</td>
                                                    <td><Button color="primary" onClick={() => { this.toView(data.name) }}> Procedi </Button></td>
                                                </tr>
                                            })}

                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    )
                }}
            </Query>
        )
    }
}

export default withRouter(withTranslation()(ViewsList));
