import React from 'react';
import ReactTable from 'react-table';
import { Row, Col} from 'reactstrap';
import 'react-table/react-table.css';
import styles from '../Styles/List.module.css'
import {defaultFilterMethod} from "../Helpers/filters";



const GenericListComponent = ({data, columns}) => {
    return (
        <div >
            <Row className={styles.tablePadding}>
                <Col md={12} className={styles.table}>
                    <ReactTable
                        data={data}
                        columns={columns}
                        minRows={0}
                        defaultPageSize={20}
                        className="-striped -highlight"
                        style={{overflow:'wrap'}}
                        previousText="Precedente"
                        nextText="Successiva"
                        noDataText="Nessun dato presente"
                        pageText="Pagina"
                        ofText="di"
                        rowsText="righe"
                        sorted={[
                            {
                                id: 'numrow'
                            }
                        ]}
                        filterable
                        defaultFilterMethod={defaultFilterMethod}
                    />
                </Col>
            </Row>
        </div>
    )
};

export  default GenericListComponent


