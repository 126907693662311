import { GET_STRUCTURE } from "../GraphQl/query";
import warning from '../../assets/warning.png'
import error from '../../assets/error.png'
import success from '../../assets/success.png'


export const getTableStructure = (data) => {
        return data ? data.__type.structure : [];
};

export const getType = (col, structureName) => {
    switch (structureName) {
        case "PubMessaggiPending":
        case "PubSintestiProgetti":
            return col.type.name ? col.type.name : col.type.ofType.name;
        default:
            return []
    }
};


export const getStructureQuery = (name) => {
    switch (name) {
        case "PubMessaggiPending":
        case "PubSintestiProgettiUff5":
            return GET_STRUCTURE;
        default:
            return ""
    }
};

export const getImage = (type) => {
    switch (type) {
        case "ERROR":
            return error;
        case "WARNING":
            return warning;
        case "SUCCESS":
            return success;
        default:
            return ""
    }
};

export const checkStatus = (status) => {
    switch (status) {
        case "Confermato":
            return  true;
        default:
            return  false
    }
};

