import {createHttpLink} from "apollo-link-http";
import {ApolloClient} from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import { setContext } from 'apollo-link-context';


const apiConfig = {
    // development: 'http://localhost:8080',
    development: 'https://moh-report-api.cbim.it',
    production: 'https://moh-report-api.cbim.it'
  };

const env = process.env.NODE_ENV || 'development';

const httpLink = createHttpLink({
    uri: `${apiConfig[env]}/graphql`
});

const cache = new InMemoryCache();


const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    resolvers:{}
});

cache.writeData({
    data: {
        isLoggedIn: false,
        userInfo:{
            id: "",
            name: "",
            instituteCode: "",
            profileCode: "",
            role:"",
            __typename:"userInfo"
        }
    },
});
