
export const defaultFilterMethod =  (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    const value = String(filter.value);
    return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(value.toLowerCase()) : true
};

export const fromCamelCaseConverter = (text) => {
    text = text.replace( /([A-Z])/g, " $1" );
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
}

