import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Button from "reactstrap/lib/Button";
import {withRouter} from "react-router-dom";
import {LOGIN} from "../../navigation/Routes";


class HomePage extends Component {
    render() {
        const {t} = this.props;
        return (
            <div>
                <h2 style={{marginBottom: '50px'}}>{t("Welcome to React")}</h2>
                <Button color="primary" onClick={() => this.props.history.push(LOGIN)}>Login</Button>
            </div>

        )
    }
}

export default withRouter(withTranslation()(HomePage))