import React, {useState} from 'react';
import {Formik} from 'formik';
import AuthFormComponent from "./AuthFormComponent";
import {useMutation} from "react-apollo";
import {useApolloClient} from "@apollo/react-hooks";
import {AUTH} from "../GraphQl/mutation";
import {useHistory} from "react-router-dom";
import {VIEWS,HOME} from "../../navigation/Routes";
import {Alert, Container} from "reactstrap";

const initialValues = {
    username: '',
    password: ''
};

const ROLES = ["ADMIN","MOH"];

function redirect(history, userRoles) {
    if (ROLES.includes(userRoles)) {
        history.push(VIEWS);
    } else history.push(HOME)
}

const AuthFormContainer = () => {
    const history = useHistory();
    const client = useApolloClient();
    const [isAlertOpen, toggleAlert] = useState(false);
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [login] = useMutation(
        AUTH,
        {
            onCompleted(data) {
                localStorage.setItem('token', data.authenticate.token);
                client.writeData({
                    data: {
                        isLoggedIn: true,
                        userInfo: {...data.authenticate.user, role: data.authenticate.user.roles[0].role}
                    }
                });
                redirect(history, data.authenticate.user.roles[0].role);
            }
        }
    );

    return (

        <Container fluid>
            <Alert color={color} isOpen={isAlertOpen} toggle={() => toggleAlert(false)}>
                {`${message}`}
            </Alert>
            <Formik
                initialValues={{...initialValues}}
                onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(false);
                    localStorage.clear();
                    try {
                        await login({variables: {username: values.username, password: values.password}});
                    } catch (e) {
                        setColor("danger");
                        setMessage("Errore: username o password non valido");
                        toggleAlert(true);
                        setTimeout(() => toggleAlert(false), 5000)

                    }
                }}>
                {(props) => (
                    <AuthFormComponent {...props}/>)
                }
            </Formik>
        </Container>)
};

export default AuthFormContainer



