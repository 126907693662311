import gql from "graphql-tag";

export const AUTH = gql`
    mutation Auth($username: String!, $password: String!)
    {
        authenticate(username: $username, password: $password)
        {
            token user {
            id, name, instituteCode, profileCode,surname
            roles {
                role
            }
        }
        }
    }`;


