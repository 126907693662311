
export const i18nTrans = {
    en: {
        translation: {
            "Welcome to React": "Sistema di Reportistica",
            "PubMessaggiPending": "Messaggi Pending",
            "PubSintestiProgettiUff5": "Sintesi Progetti Ufficio 5",
            "pubMessaggiPending": "Messaggi Pending",
            "pubSintestiProgettiUff5": "Sintesi Progetti Ufficio 5"
        }
    }
};

export const lang = "en";
