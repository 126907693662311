import gql from "graphql-tag";

export const GET_VIEWS = gql`
    query{
        views{
            name
        }
    }`;

export const GET_STRUCTURE = gql`
    query structure($structureName: String!){
        __type(name: $structureName) {
            name
            structure: fields {
                Header: name
                accessor: name
                Header: name
                accessor: name
                type {
                    name
                    kind
                    ofType {
                        name
                        kind
                    }
                }
            }
        }
    }`;

export const GET_PUBMESSAGGIPENDING = gql`
    query{
        pubMessaggiPending{
          id
          tipoMessaggio
          codiceProgetto
          codice
          istDescBreve
          idIstituto
          mittenteDescBreve
          idInvio
          idPersPresaCarico
          nomePersPresaCarico
          cognomePersPresaCarico
          dataPresaCarico
          rendicontatoImporto
          dataInvio
          oggetto
          testo
        }
    }`;

export const GET_PUBSINTESTIPROGETTIUFF5 = gql`
    query{
        pubSintestiProgettiUff5{
          id
          idProgetto
          codiceProgetto
          istNomeBreve
          idIstituto
          titolo
          dataInizio
          dataFine
          dataProroga
          finAssegnato
          finRendicontato
          finErogato
          finResiduo
          finDaPagare
          finRendDaApprovare
        }
      }`;

export const GET_LOGIN_DATA = gql`
    query {
        isLoggedIn @client
        userInfo @client{
            instituteCode
            role
            name
            surname
        }
    }
`;


