import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {GET_PUBSINTESTIPROGETTIUFF5} from "../GraphQl/query";
import {Query} from 'react-apollo';
import GenericListContainer from "../GenericList/GenericListContainer";

class PubSintestiProgettiUff5List extends Component {
    render() {
        return (
            <Query query={GET_PUBSINTESTIPROGETTIUFF5}>
                {({loading, error, data}) => {
                    if (loading) return <div>Fetching</div>;
                    if (error) return error.message;

                    return (
                        <GenericListContainer list={data.pubSintestiProgettiUff5} structureName={"PubSintestiProgettiUff5"}
                        />
                    )
                }}
            </Query>
        )
    }
}

export default withRouter(PubSintestiProgettiUff5List);
