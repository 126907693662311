import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import {
    Button,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem
} from 'reactstrap';
import { HOME, VIEWS } from '../../navigation/Routes';
import { client } from '../GraphQl';
import logo from '../../assets/banner1.png';

import styles from './Navbar.module.css';
import { useQuery } from "@apollo/react-hooks";
import { GET_LOGIN_DATA } from "../GraphQl/query";

const NavbarComponent = ({ history, location: { pathname, state } }) => {
    const { data: auth } = useQuery(GET_LOGIN_DATA);
    const loggedIn = (auth || {}).isLoggedIn;
    const name = (auth || {}).userInfo ? (auth || {}).userInfo.name : '';
    const surname = (auth || {}).userInfo ? (auth || {}).userInfo.surname : '';
    const viewsPage = pathname.match(RegExp(VIEWS));
    const onLogout = () => {
        localStorage.clear();
        client.resetStore();
        history.push(HOME);
    };
    return (
        <Navbar light expand="md" className={styles.navbar}>
            <NavbarBrand>
                <Nav className="flex-column" navbar>
                    <NavItem>
                        <img src={logo} className={styles.logo} alt="Rendicontazione Finanziaria" />
                    </NavItem>
                </Nav>
            </NavbarBrand>
            <Nav>
                    <NavItem>
                    <h2 className={styles.h2}>Workflow della Ricerca 2.0 - Sistema di Reportistica</h2>
                    </NavItem>
                </Nav>
            <Nav className="ml-auto" navbar>
                {loggedIn && !viewsPage && <NavItem className={styles.left}>
                    <Button onClick={() => history.goBack()}>Indietro</Button>
                </NavItem>}
                {loggedIn && <>
                    <NavItem className={styles.left}>
                        <div className={styles.user}>{name} {surname}</div>
                    </NavItem>
                    <NavItem className={styles.left}>
                        <div><Button color="primary" onClick={onLogout}>Logout</Button></div>
                    </NavItem>
                </>}
            </Nav>
        </Navbar>
    );
};

NavbarComponent.propTypes = {
    isAuth: PropTypes.bool,
};

NavbarComponent.defaultProps = {
    isAuth: false
};

export default withRouter(NavbarComponent);
