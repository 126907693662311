import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Unauthorized from '../components/Unauthorized';
import * as routes from './Routes';
import HomePage from "../components/Home";
import AuthFormContainer from "../components/AuthForm/AuthFormContainer";
import ViewsList from '../components/Views';
import PubMessaggiPendingList from '../components/PubMessaggiPending/PubMessaggiPendingListContainer';
import PubSintestiProgettiUff5List from '../components/PubSintestiProgettiUff5';

export const AppRoutes = () =>
    <Switch>
        <Route path={routes.HOME} exact component={() => <HomePage/>}/>
        <Route path={routes.LOGIN} exact component={() => <AuthFormContainer/>}/>
        <Route exact path={routes.UNAUTHORIZED} component={() => <Unauthorized/>}/>
        <Route path={routes.VIEWS} exact component={() => <ViewsList/>}/>
        <Route path={routes.PUBMESSAGGIPENDING} exact component={() => <PubMessaggiPendingList/>}/>
        <Route path={routes.PUBSINTESTIPROGETTIUFF5} exact component={() => <PubSintestiProgettiUff5List/>}/>


    </Switch>;

export default AppRoutes;
